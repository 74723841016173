import { ALL_SKILLS, SKILL_SELECTED } from './ExperienceSelectionChoices';
import originalExperienceData from '../dal/ExperienceDAL';

import util from '../util';

export function displaySkills() {
  return function(dispatch) {
    dispatch({
      type: ALL_SKILLS,
      payload: util.deepObjectCopy(originalExperienceData.skills)
    });
  }
}

export function selectSkill(posChosen) {
  console.log('Skills selected');

  return function(dispatch, getState) {
    const currentExperience = getState().experiences;
    let expToUse = originalExperienceData;
    let lastChosenId = posChosen.id; 
    if (currentExperience.lastPositionChosenId && currentExperience.lastPositionChosenId === posChosen.id) {
      expToUse = currentExperience;
      lastChosenId = "";
    }
    const currentData = util.deepObjectCopy(expToUse);
    const skillData = currentData.skills;
    for (const skillId of posChosen.relatedSkills)
      skillData[skillId].isHighlighted = !skillData[skillId].isHighlighted;
    dispatch({
      type: SKILL_SELECTED,
      payload: skillData,
      lastChosenId: lastChosenId
    });
  }
}