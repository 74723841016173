import { ALL_POSITIONS, POSITION_SELECTED } from './ExperienceSelectionChoices';
import originalExperienceData from '../dal/ExperienceDAL';

import util from '../util';

export function displayPositions() {
  return function(dispatch) {
    dispatch({
      type: ALL_POSITIONS,
      payload: util.deepObjectCopy(originalExperienceData.positions)
    });
  }
}

export function selectPosition(skillChosen) {
  console.log('Positions selected');
  
  return function(dispatch, getState) {
    const currentExperience = getState().experiences;
    let expToUse = originalExperienceData;
    let lastChosenId = skillChosen.id; 
    if (currentExperience.lastSkillChosenId && currentExperience.lastSkillChosenId === skillChosen.id) {
      expToUse = currentExperience;
      lastChosenId = "";
    }
    const currentData = util.deepObjectCopy(expToUse);
    const positionData = currentData.positions;
    for (const posId of skillChosen.relatedPositions)
      positionData[posId].isHighlighted = !positionData[posId].isHighlighted;
    dispatch({
      type: POSITION_SELECTED,
      payload: positionData,
      lastChosenId: lastChosenId
    });
  }
}

// export selectPosition;