import React from "react";
import './Education.css';

function Education() {
  return (
    <div className='education center'>
      <h3>Clark University</h3>
      <p>Worcester, MA
        <br></br>
        Graduated in May 2017
      </p>
      <p>Bachelor in Computer Science</p>
    </div>
  );
}

export default Education;