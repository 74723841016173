import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Provider } from 'react-redux';

import PersonalInfo from './components/PersonalInfo';
import Experience from './components/Experience';
import exprienceStore from './store/ExperienceStore';
import Education from './components/Education';

function App() {
  return (
    <div className="app">
      <header>
        
      </header>
      <div>
        <PersonalInfo />
      </div>
      
      <Provider store={exprienceStore}>
      <div>
        <Experience />
      </div>
      </Provider>
      <div>
        <Education />
      </div>
      <div className="app-footer">
        <img src={logo} className="app-logo" alt="logo" />
        <p>
          This resume is powered by React JS
          <br></br>
          Copyright © 2020 Viet Do
        </p>
      </div>
    </div>    
  );
}

export default App;
