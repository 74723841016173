import React from "react";
import './Position.css';

function Position(props) {
  return (
    <div className={`position${props.highlighted ? ' highlight' : ''}`} id={props.id}>
      <h3>{props.company}</h3>
      <p className="positionspec">{props.team}
        <br></br>
        {props.position}
      </p>
      <div className='description'>
        {(props.descriptions || []).map(desc => (
          <div>{desc}</div>
        ))}
      </div>
    </div>
  );
}

export default Position;