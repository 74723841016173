import { ALL_POSITIONS, POSITION_SELECTED, ALL_SKILLS, SKILL_SELECTED } from '../actions/ExperienceSelectionChoices';

import initialState from '../dal/ExperienceDAL';

function reduceFn(state = initialState, action) {
  switch (action.type) {
    case POSITION_SELECTED:
      return {
        positions: action.payload,
        skills: initialState.skills,
        selection: 'position',
        lastSkillChosenId: action.lastChosenId
      };
    case SKILL_SELECTED:
      return {
        positions: initialState.positions,
        skills: action.payload,
        selection: 'skill',
        lastPositionChosenId: action.lastChosenId
      };
    case ALL_POSITIONS:
    case ALL_SKILLS:
      return initialState;
    default: 
      return state;
  }
}

export default reduceFn;