import React from "react";
import './Introduction.css';

function Introduction(props) {
  return (
    <div className='introduction'>
      <div className="x" onClick={props.xfn}> X </div>
      This is an interactive resume. 
      <br></br>
      Please click on "Related Skills" to highlight skills acquired.
      <br></br>
      Please click on any skill to see all the relevant experiences.
      <br></br>
      To toggle, click on same item twice.
    </div>
  );
}

export default Introduction;