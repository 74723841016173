import React from 'react';
import './PersonalInfo.css';

function PersonalInfo() {
  return (
    <div id="myname">
      <h1>Viet Do</h1>
      <p>Seattle, WA
        <br></br>
        viet.vdd@gmail.com | (508) 556-1105
        <br></br>
        vietdo.dev
      </p>
    </div>
  );
}

export default PersonalInfo;