import React, { Component } from "react";
// import { useState, useContext } from "react";
import './Experience.css';

import { connect } from 'react-redux';
import { displayPositions, selectPosition } from '../actions/PositionSelectedAction';
import { displaySkills, selectSkill } from '../actions/SkillSelectedAction';

import Position from './Position';
import Skill from './Skill';

import Introduction from './Introduction';

class Experience extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selection: 'none',
      showIntro: true
    }

    this.onClick = this.onClick.bind(this);
    this.xOnClick = this.xOnClick.bind(this);
    this.PopUpIntroduction = this.PopUpIntroduction.bind(this);
  }

  componentWillMount() {
    this.props.displayPositions();
    this.props.displaySkills();
  }

  /*
    this is to update props
  */
  // componentWillReceiveProps(nextProps) {
  //   switch (nextProps.selection) {
  //     case 'position':
  //       this.props.selectPosition();
  //       break;
  //     case 'skills':
  //       this.props.selectSkill();
  //       break;
  //     default:
  //       this.props.displayPositions();
  //       this.props.displaySkills();
  //   }
  // }

  onClick(e) {
    e.preventDefault();
    console.log(e.target);
    console.log(e.target.getAttribute('selectionid'));
    console.log(e.target.getAttribute('typeselected'));

    if (e.target.getAttribute('typeselected') === "position") {
      window.location.href= `#${(this.props.skills[e.target.getAttribute('selectionid')].relatedPositions || ['myname'])[0]}`; 
      this.props.selectPosition(this.props.skills[e.target.getAttribute('selectionid')]);
    }
    if (e.target.getAttribute('typeselected') === "skill") {
      this.props.selectSkill(this.props.positions[e.target.getAttribute('selectionid')]);
    }
  }

  xOnClick(e) {
    console.log(e.target);
    this.setState(state => ({
      showIntro: false
    }));
  }

  PopUpIntroduction(props) {
    if (!props.showIntro) {
      return null;
    }
    return (
      <div className="center blurall"> 
        <Introduction xfn={this.xOnClick}/>
      </div>      
    );
  }

  render() {
    return (
      <div className="main-content">

        <div> 
          <this.PopUpIntroduction showIntro={this.state.showIntro} /> 
        </div>

        <div className="skill-base-left">
          <h2> Cloud 
            <br></br>
            Technologies </h2>
          {Object.values(this.props.skills).filter(skill => skill.id.startsWith("cloud")).map(skill => (
            <div onClick={this.onClick}>
                <Skill id={skill.id} typeselected='position' name={skill.skillName} highlighted={skill.isHighlighted ? true : false} />
            </div>
          ))}
          <h2> Technologies </h2>
          {Object.values(this.props.skills).filter(skill => skill.id.startsWith("tech")).map(skill => (
            <div onClick={this.onClick}>
                <Skill id={skill.id} typeselected='position' name={skill.skillName} highlighted={skill.isHighlighted ? true : false} />
            </div>
          ))}
        </div>

        <div className="skill-base-right">
          <h2> Libraries / 
            <br></br>
            Frameworks </h2>
          {Object.values(this.props.skills).filter(skill => skill.id.startsWith("lib")).map(skill => (
            <div onClick={this.onClick}>
                <Skill id={skill.id} typeselected='position' name={skill.skillName} highlighted={skill.isHighlighted ? true : false} />
            </div>
          ))}
          <h2> Tools </h2>
          {Object.values(this.props.skills).filter(skill => skill.id.startsWith("tool")).map(skill => (
            <div onClick={this.onClick}>
                <Skill id={skill.id} typeselected='position' name={skill.skillName} highlighted={skill.isHighlighted ? true : false} />
            </div>
          ))}
          <h2> Languages </h2>
          {Object.values(this.props.skills).filter(skill => skill.id.startsWith("lang")).map(skill => (
            <div onClick={this.onClick}>
                <Skill id={skill.id} typeselected='position' name={skill.skillName} highlighted={skill.isHighlighted ? true : false} />
            </div>
          ))}
        </div>

        <div className="pos-base center">
          {Object.values(this.props.positions).map(pos => (
            <div>
              <Position id={pos.id} company={pos.company} team={pos.team} position={pos.position} 
                highlighted={pos.isHighlighted ? true : false} descriptions={pos.descriptions} />
              <div className={`relatedskill ${this.state.showIntro ? '' : 'animation'}`} selectionid={pos.id} typeselected='skill' onClick={this.onClick}> &gt; Click here for Related Skills &lt; </div>
              <br></br>
            </div>
          ))}
        </div>
      </div>
    );
  }

}

// export default Experience;

const mapStateToProps = state => ({
  positions: state.experiences.positions,
  skills: state.experiences.skills
});

export default connect(mapStateToProps, { displayPositions, selectPosition, displaySkills, selectSkill })(Experience);